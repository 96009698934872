import { REEBELO_TZ_NAME, ReebeloStoreT } from '@lambda/reebelo';
import { createHash } from 'crypto';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const flatten = (
  obj: Record<string, any>,
  resolveFalsy: string | undefined = undefined, // Resolves empty and falsy to value
  roots = [],
  sep = '.',
): Record<string, any> =>
  Object.keys(obj).reduce((memo, prop) => {
    const isEmpty = obj[prop] ? Object.keys(obj[prop]).length <= 0 : false;

    return {
      ...memo,
      ...((Object.prototype.toString.call(obj[prop]) === '[object Object]' ||
        Array.isArray(obj[prop])) &&
      (resolveFalsy == null || !isEmpty)
        ? flatten(obj[prop], resolveFalsy, roots.concat([prop as never]), sep)
        : {
            [roots.concat([prop as never]).join(sep)]:
              resolveFalsy != null && (obj[prop] == null || isEmpty)
                ? resolveFalsy
                : obj[prop],
          }),
    };
  }, {});

export const generateHash = (str: string): string => {
  const hash = createHash('sha256');

  hash.update(str);

  return hash.digest('hex');
};

export const handleFreeReturn = (
  store: ReebeloStoreT,
  freeReturnDays: number,
  order?: { processedAt: string | null | undefined },
) => {
  const isNA = ['reebelo-us', 'reebelo-ca', 'reebelo-dev'].includes(store);

  if (!isNA) return freeReturnDays;
  let date = null;

  if (order) {
    date = order.processedAt
      ? dayjs(order.processedAt).tz(REEBELO_TZ_NAME[store])
      : null;
  } else {
    date = dayjs().tz(REEBELO_TZ_NAME[store]);
  }

  if (!date) return freeReturnDays;

  const nov15th = dayjs('2024-11-15').tz(REEBELO_TZ_NAME[store]);
  const nov30th = dayjs('2024-11-30').tz(REEBELO_TZ_NAME[store]);
  const dec14th = dayjs('2024-12-14').tz(REEBELO_TZ_NAME[store]);
  const add30days =
    (date.isAfter(nov15th, 'date') && date.isBefore(nov30th, 'date')) ||
    date.isSame(nov15th, 'date');
  const add15days =
    (date.isAfter(nov30th, 'date') && date.isBefore(dec14th, 'date')) ||
    date.isSame(nov30th, 'date');

  if (add30days) return 30 + freeReturnDays;
  if (add15days) return 15 + freeReturnDays;

  return freeReturnDays;
};

export const isHolidayReturnEligible = (
  store: ReebeloStoreT,
  processedAt: string | null | undefined,
) => {
  const isNA = ['reebelo-us', 'reebelo-ca', 'reebelo-dev'].includes(store);

  if (!isNA || !processedAt) return false;
  const nov15th = dayjs('2024-11-15').tz(REEBELO_TZ_NAME[store]);
  const dec14th = dayjs('2024-12-14').tz(REEBELO_TZ_NAME[store]);
  const jan15th = dayjs('2025-01-15').tz(REEBELO_TZ_NAME[store]);
  const date = dayjs(processedAt).tz(REEBELO_TZ_NAME[store]);
  const today = dayjs().tz(REEBELO_TZ_NAME[store]);
  const isVaild =
    date.isSameOrAfter(nov15th, 'date') &&
    date.isBefore(dec14th, 'date') &&
    today.isSameOrBefore(jan15th, 'date');

  return isVaild;
};
