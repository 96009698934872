export enum UserRoute {
  LOGIN = '/v1/shopify/auth',
  FORGOT = '/v1/shopify/auth/forgot',
  RESET = '/v1/shopify/auth/reset',
  LOGOUT = '/v1/shopify/accesstoken',
  USER = '/v1/shopify/customer',
  MULTIPASS = '/v1/shopify/multipass',
  CHECKOUT = '/v1/shopify/multipass/checkout-url',
  SEARCH = '/v1/customer',
  CUSTOMER = '/v1/customer/{customerId}',
  ADDRESS = '/v1/customer/{customerId}/address',
  ADDRESSES = '/v1/customer/{customerId}/addresses/{addressId}',
  SUBSCRIPTION = '/v1/customer/{customerId}/subscription',
  ALIAS = '/v1/customer/{customerId}/aliases',
  VERIFICATION = '/v1/customer/{customerId}/verification',
  GET_VERIFICATION = '/v1/customer/{customerId}/verification/{verificationId}',
}

export enum CustomerAddressType {
  Billing = 'Billing',
  Shipping = 'Shipping',
}

export enum CustomerSubscriptionChannel {
  SMS = 'sms',
  EMAIL = 'email',
}

export enum CustomerSubscriptionSource {
  FOOTER = 'footer',
  CHECKOUT = 'checkout',
  SIGNUP = 'signup',
  BUYBACK = 'buyback',
  CHECKOUT_THANK_YOU = 'checkout_thank_you',
}

export enum CustomerSubscriptionStatus {
  OPTED_IN = 'opted_in',
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
}

export enum CustomerAliasSource {
  Braze = 'braze',
  Segment = 'segment',
  Onfido = 'onfido',
}

export enum CustomerVerificationStatus {
  AwaitingInput = 'awaiting_input',
  Processing = 'processing',
  Abandoned = 'abandoned',
  Error = 'error',
  Approved = 'approved',
  Review = 'review',
  Declined = 'declined',
}
