/* eslint-disable import/no-mutable-exports */
import { REEBELO_CURRENCY } from '@lambda/reebelo';
import config from './configs';

export default config;

if (
  process.env.STORE == null &&
  process.env.NEXT_PUBLIC_STORE == null &&
  process.env.NODE_ENV !== 'test'
) {
  throw Error(
    `STORE is not defined: ${process.env.STORE} - ${process.env.NEXT_PUBLIC_STORE}`,
  );
}

if (process.env.NODE_ENV === 'test') {
  // add env in test
  // eslint-disable-next-line
  const env = require('../scripts/env');

  process.env = { ...process.env, ...env };
}

export const priceFormater = {
  short: (number: number | string, showDecimals = true) => {
    const value = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'symbol', // note that Safari does not support 'symbolCode'
      currency: REEBELO_CURRENCY[config.store],
      minimumFractionDigits: showDecimals ? 2 : 0,
      maximumFractionDigits: showDecimals ? 2 : 0,
    }).format(typeof number === 'number' ? number : Number(number));

    return value.replace('SGD ', '$').replace('NZ$', '$');
  },

  long: (number: number | string) => {
    const value = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'symbol', // Shows A$
      currency: REEBELO_CURRENCY[config.store],
    }).format(typeof number === 'number' ? number : Number(number));

    if (config.store === 'reebelo-au') return value.replace('$A', '$AU');

    return value;
  },
  code: (number: number | string) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'code', // Shows AUD
      currency: REEBELO_CURRENCY[config.store],
    }).format(typeof number === 'number' ? number : Number(number)),
};
